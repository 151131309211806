import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Routing } from '../../app.routes';
import { AcsApiService } from '../services/acs-api.service';

export const authGuard: CanActivateFn = (route, state) => {
  const apiService: AcsApiService = inject(AcsApiService);
  const router = inject(Router);
  const isAuthorize = !!apiService.getToken();
  if (!isAuthorize) {
    router.navigate([Routing.UNAUTHORIZED]);
  }
  return isAuthorize;
};
