/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SuccessResponse } from '../../models/success-response';

export interface GetRequestAccess$Params {
  Parameters: {
'mode'?: 'expat_internal' | 'expat_external_client' | 'expat_external_producer';
'entity'?: 'clients' | 'policies';
};
  '_locale': string;
  uuid: string;
}

export function getRequestAccess(http: HttpClient, rootUrl: string, params: GetRequestAccess$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessResponse>> {
  const rb = new RequestBuilder(rootUrl, getRequestAccess.PATH, 'get');
  if (params) {
    rb.query('Parameters', params.Parameters, {});
    rb.path('_locale', params['_locale'], {});
    rb.path('uuid', params.uuid, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SuccessResponse>;
    })
  );
}

getRequestAccess.PATH = '/{_locale}/api/1.0/request-access/{uuid}';
