/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BusinessSectorList } from '../models/business-sector-list';
import { CountryList } from '../models/country-list';
import { ExpatClientResponse } from '../models/expat-client-response';
import { ExpatPricingResponse } from '../models/expat-pricing-response';
import { ExpatProjectGetResponse } from '../models/expat-project-get-response';
import { getExpatClientGet } from '../fn/operations/get-expat-client-get';
import { GetExpatClientGet$Params } from '../fn/operations/get-expat-client-get';
import { getGetExpatTarificationLists } from '../fn/operations/get-get-expat-tarification-lists';
import { GetGetExpatTarificationLists$Params } from '../fn/operations/get-get-expat-tarification-lists';
import { getGetExpatTarificationProject } from '../fn/operations/get-get-expat-tarification-project';
import { GetGetExpatTarificationProject$Params } from '../fn/operations/get-get-expat-tarification-project';
import { getRequestAccess } from '../fn/operations/get-request-access';
import { GetRequestAccess$Params } from '../fn/operations/get-request-access';
import { postExpatTarificationCalculate } from '../fn/operations/post-expat-tarification-calculate';
import { PostExpatTarificationCalculate$Params } from '../fn/operations/post-expat-tarification-calculate';
import { postExpatTarificationSaveProject } from '../fn/operations/post-expat-tarification-save-project';
import { PostExpatTarificationSaveProject$Params } from '../fn/operations/post-expat-tarification-save-project';
import { postSendVerificationCode } from '../fn/operations/post-send-verification-code';
import { PostSendVerificationCode$Params } from '../fn/operations/post-send-verification-code';
import { postValidateVisitorToken } from '../fn/operations/post-validate-visitor-token';
import { PostValidateVisitorToken$Params } from '../fn/operations/post-validate-visitor-token';
import { putExpatTarificationUpdateProject } from '../fn/operations/put-expat-tarification-update-project';
import { PutExpatTarificationUpdateProject$Params } from '../fn/operations/put-expat-tarification-update-project';
import { SuccessResponse } from '../models/success-response';
import { TokenResponse } from '../models/token-response';
import { TokenUuidResponse } from '../models/token-uuid-response';

@Injectable({ providedIn: 'root' })
export class ApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRequestAccess()` */
  static readonly GetRequestAccessPath = '/{_locale}/api/1.0/request-access/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequestAccess()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestAccess$Response(params: GetRequestAccess$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessResponse>> {
    return getRequestAccess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRequestAccess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestAccess(params: GetRequestAccess$Params, context?: HttpContext): Observable<SuccessResponse> {
    return this.getRequestAccess$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessResponse>): SuccessResponse => r.body)
    );
  }

  /** Path part for operation `postSendVerificationCode()` */
  static readonly PostSendVerificationCodePath = '/{_locale}/api/1.0/request-access';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSendVerificationCode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postSendVerificationCode$Response(params: PostSendVerificationCode$Params, context?: HttpContext): Observable<StrictHttpResponse<TokenResponse>> {
    return postSendVerificationCode(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postSendVerificationCode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postSendVerificationCode(params: PostSendVerificationCode$Params, context?: HttpContext): Observable<TokenResponse> {
    return this.postSendVerificationCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<TokenResponse>): TokenResponse => r.body)
    );
  }

  /** Path part for operation `getExpatClientGet()` */
  static readonly GetExpatClientGetPath = '/{_locale}/api/1.0/modules/acs/expat/client/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExpatClientGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExpatClientGet$Response(params: GetExpatClientGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ExpatClientResponse>> {
    return getExpatClientGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExpatClientGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExpatClientGet(params: GetExpatClientGet$Params, context?: HttpContext): Observable<ExpatClientResponse> {
    return this.getExpatClientGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExpatClientResponse>): ExpatClientResponse => r.body)
    );
  }

  /** Path part for operation `getGetExpatTarificationProject()` */
  static readonly GetGetExpatTarificationProjectPath = '/{_locale}/api/1.0/modules/acs/expat/project/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGetExpatTarificationProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGetExpatTarificationProject$Response(params: GetGetExpatTarificationProject$Params, context?: HttpContext): Observable<StrictHttpResponse<ExpatProjectGetResponse>> {
    return getGetExpatTarificationProject(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGetExpatTarificationProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGetExpatTarificationProject(params: GetGetExpatTarificationProject$Params, context?: HttpContext): Observable<ExpatProjectGetResponse> {
    return this.getGetExpatTarificationProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExpatProjectGetResponse>): ExpatProjectGetResponse => r.body)
    );
  }

  /** Path part for operation `putExpatTarificationUpdateProject()` */
  static readonly PutExpatTarificationUpdateProjectPath = '/{_locale}/api/1.0/modules/acs/expat/project/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putExpatTarificationUpdateProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putExpatTarificationUpdateProject$Response(params: PutExpatTarificationUpdateProject$Params, context?: HttpContext): Observable<StrictHttpResponse<TokenUuidResponse>> {
    return putExpatTarificationUpdateProject(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putExpatTarificationUpdateProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putExpatTarificationUpdateProject(params: PutExpatTarificationUpdateProject$Params, context?: HttpContext): Observable<TokenUuidResponse> {
    return this.putExpatTarificationUpdateProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<TokenUuidResponse>): TokenUuidResponse => r.body)
    );
  }

  /** Path part for operation `postExpatTarificationSaveProject()` */
  static readonly PostExpatTarificationSaveProjectPath = '/{_locale}/api/1.0/modules/acs/expat/project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postExpatTarificationSaveProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postExpatTarificationSaveProject$Response(params: PostExpatTarificationSaveProject$Params, context?: HttpContext): Observable<StrictHttpResponse<TokenUuidResponse>> {
    return postExpatTarificationSaveProject(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postExpatTarificationSaveProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postExpatTarificationSaveProject(params: PostExpatTarificationSaveProject$Params, context?: HttpContext): Observable<TokenUuidResponse> {
    return this.postExpatTarificationSaveProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<TokenUuidResponse>): TokenUuidResponse => r.body)
    );
  }

  /** Path part for operation `getGetExpatTarificationLists()` */
  static readonly GetGetExpatTarificationListsPath = '/{_locale}/api/1.0/modules/acs/expat/custom-lists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGetExpatTarificationLists()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGetExpatTarificationLists$Response(params: GetGetExpatTarificationLists$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'status'?: number;
'data'?: {
'minimum_subscription_period'?: number;
'nationality_countries'?: CountryList;
'expatriation_countries'?: CountryList;
'business_sectors'?: BusinessSectorList;
'expat_project_deductible'?: Array<number>;
};
}>> {
    return getGetExpatTarificationLists(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGetExpatTarificationLists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGetExpatTarificationLists(params: GetGetExpatTarificationLists$Params, context?: HttpContext): Observable<{
'status'?: number;
'data'?: {
'minimum_subscription_period'?: number;
'nationality_countries'?: CountryList;
'expatriation_countries'?: CountryList;
'business_sectors'?: BusinessSectorList;
'expat_project_deductible'?: Array<number>;
};
}> {
    return this.getGetExpatTarificationLists$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'status'?: number;
'data'?: {
'minimum_subscription_period'?: number;
'nationality_countries'?: CountryList;
'expatriation_countries'?: CountryList;
'business_sectors'?: BusinessSectorList;
'expat_project_deductible'?: Array<number>;
};
}>): {
'status'?: number;
'data'?: {
'minimum_subscription_period'?: number;
'nationality_countries'?: CountryList;
'expatriation_countries'?: CountryList;
'business_sectors'?: BusinessSectorList;
'expat_project_deductible'?: Array<number>;
};
} => r.body)
    );
  }

  /** Path part for operation `postExpatTarificationCalculate()` */
  static readonly PostExpatTarificationCalculatePath = '/{_locale}/api/1.0/modules/acs/expat/pricing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postExpatTarificationCalculate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postExpatTarificationCalculate$Response(params: PostExpatTarificationCalculate$Params, context?: HttpContext): Observable<StrictHttpResponse<ExpatPricingResponse>> {
    return postExpatTarificationCalculate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postExpatTarificationCalculate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postExpatTarificationCalculate(params: PostExpatTarificationCalculate$Params, context?: HttpContext): Observable<ExpatPricingResponse> {
    return this.postExpatTarificationCalculate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExpatPricingResponse>): ExpatPricingResponse => r.body)
    );
  }

  /** Path part for operation `postValidateVisitorToken()` */
  static readonly PostValidateVisitorTokenPath = '/{_locale}/api/1.0/modules/acs/expat/validate-visitor-token';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postValidateVisitorToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postValidateVisitorToken$Response(params: PostValidateVisitorToken$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessResponse>> {
    return postValidateVisitorToken(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postValidateVisitorToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postValidateVisitorToken(params: PostValidateVisitorToken$Params, context?: HttpContext): Observable<SuccessResponse> {
    return this.postValidateVisitorToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessResponse>): SuccessResponse => r.body)
    );
  }

}
