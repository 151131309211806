/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BeneficiaryPricing } from '../../models/beneficiary-pricing';
import { ExpatPricingResponse } from '../../models/expat-pricing-response';
import { PricingInformations } from '../../models/pricing-informations';

export interface PostExpatTarificationCalculate$Params {
  '_locale': string;
      body: {
'pricing'?: PricingInformations;
'beneficiaries'?: Array<BeneficiaryPricing>;
'product'?: string;
}
}

export function postExpatTarificationCalculate(http: HttpClient, rootUrl: string, params: PostExpatTarificationCalculate$Params, context?: HttpContext): Observable<StrictHttpResponse<ExpatPricingResponse>> {
  const rb = new RequestBuilder(rootUrl, postExpatTarificationCalculate.PATH, 'post');
  if (params) {
    rb.path('_locale', params['_locale'], {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ExpatPricingResponse>;
    })
  );
}

postExpatTarificationCalculate.PATH = '/{_locale}/api/1.0/modules/acs/expat/pricing';
