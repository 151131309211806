import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  OnInit,
  Signal
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, of } from 'rxjs';
import { Routing } from '../../app.routes';
import { QueryParamMapModel } from '../../core/resolvers/project.resolver';
import { AcsApiService } from '../../core/services/acs-api.service';
import { TranslatePipe } from '../../shared/pipes/translate.pipe';
import { LanguageStore } from '../../shared/services/language.store';
import { SnackBarService } from '../../shared/services/snack-bar.service';
import { StepperService } from '../../shared/services/stepper.service';

@Component({
  selector: 'acs-reprise-projet',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    TranslatePipe
  ],
  templateUrl: './reprise-projet.component.html',
  styles: `
    @media only screen and (min-width: 1224px) {
      form {
        width: 418px;
        margin-left: 15%;
      }

      .layout-xl {
        display: flex;
        justify-content: space-evenly;
      }

      .full-height {
        height: 65vh;
      }
    }
  `,
  providers: [TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RepriseProjetComponent implements OnInit {
  public form: FormGroup = inject(FormBuilder).group({
    code: [
      null,
      [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
        Validators.pattern('[0-9]{6}')
      ]
    ]
  });
  readonly #activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  readonly #acsApiService: AcsApiService = inject(AcsApiService);
  readonly #stepperService: StepperService = inject(StepperService);
  readonly #snackBarService: SnackBarService = inject(SnackBarService);
  readonly #translatePipe = inject(TranslatePipe);
  readonly #languageStore = inject(LanguageStore);
  readonly #router: Router = inject(Router);
  #uuid!: string;
  #producerCode!: string;
  #mode!: string;
  #entity!: string;

  public toastMessage: Signal<string> = computed(() => {
    this.#languageStore.currentLanguageCode();
    return this.#translatePipe.transform('recovery.toast');
  });

  /**
   * A l init du composant on verifie que les parametre de l url sont bon
   * Puis on envoye une requete de code de reprise projet ( envoye par email )
   */
  public ngOnInit(): void {
    this.#stepperService.$isVisible.set(false);
    const params = this.#activatedRoute.snapshot.data[
      'queryParams'
    ] as QueryParamMapModel;
    if (params.uuid && params.entity && params.mode && params.producerCode) {
      this.#uuid = params.uuid;
      this.#entity = params.entity;
      this.#producerCode = params.producerCode;
      this.#mode = params.mode;
      this.#acsApiService
        .getAccessCode(params.uuid)
        .pipe(
          catchError((err) => {
            this.#snackBarService.errorMessage(err.error.message);
            return of(null);
          })
        )
        .subscribe({
          next: (res) => {
            if (res) {
              this.#snackBarService.success(this.toastMessage());
            }
          }
        });
    }
  }

  private get codeCtrl(): FormControl {
    return this.form.get('code') as FormControl;
  }

  /**
   * Soumission du formulaire pour l envoie du code recu par email
   * Set les parametre d'url et redirige vers la page projet
   */
  public confirmCode(): void {
    if (this.#uuid) {
      this.#acsApiService
        .requestAccessToken(this.#uuid, this.codeCtrl.value)
        .pipe(
          catchError((err) => {
            this.#snackBarService.errorMessage(err.error.message);
            return of(null);
          })
        )
        .subscribe((res) => {
          if (res) {
            this.#acsApiService.setToken(res.data.token);
            this.#router.navigate([Routing.PROJECT], {
              queryParams: {
                token: res.data.token,
                uuid: this.#uuid,
                producer_code: this.#producerCode,
                mode: this.#mode,
                entity: this.#entity
              }
            });
          }
        });
    }
  }
}
