<div
  class="height-50vh is-size-4 is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
  <!-- <p class="has-text-danger">
    {{ message | translate }}
  </p>
  <p class="mt-5">{{ serverMessageError | translate }}</p> -->
  <div class="bg-blue error-panel has-text-justified">
    <h1 class="is-size-4 is-flex is-align-items-center gap-s mb-3">
      <mat-icon color="info">info</mat-icon>
      {{ 'error.title' | translate }}
    </h1>
    <p class="is-size-6" innerHTML="{{ 'error.500' | translate }}"></p>
  </div>
  <p class="is-size-6 mt-2 has-text-grey-light">
    {{ serverMessageError | translate }}
  </p>
</div>
