import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  readonly #snackBar: MatSnackBar = inject(MatSnackBar);

  public errorMessage(message: string): void {
    const snackBarRef = this.#snackBar.open(message, undefined, {
      duration: 300000,
      verticalPosition: 'bottom',
      panelClass: 'error-snackbar'
    });
    // Ferme la snack bar au click
    snackBarRef.afterOpened().subscribe(() => {
      const snackBarContainer = document.querySelector(
        '.cdk-overlay-container'
      );
      if (snackBarContainer) {
        snackBarContainer.addEventListener('click', () => {
          snackBarRef.dismiss();
        });
      }
    });
  }

  public success(message: string): void {
    this.#snackBar.open(message, undefined, {
      duration: 3000,
      verticalPosition: 'bottom',
      panelClass: 'success-snackbar'
    });
  }
}
