import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Routing } from '../../app.routes';
import { GlobalStore } from '../../shared/services/global.store';

export const stepperGuard: CanActivateFn = (route, state) => {
  const store: GlobalStore = inject(GlobalStore);
  const router = inject(Router);
  const url = route.routeConfig?.path;
  let isAuthorized: boolean = false;
  const path = store.getGuardPath();
  // Si pas de path renseigner dans le sessionStorage -> 403
  if (!path) {
    router.navigate([Routing.UNAUTHORIZED]);
  } else if (url) {
    isAuthorized = url === path;
  }

  return isAuthorized;
};
