import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { Routing } from '../../app.routes';
import { QueryParamMapModel } from './project.resolver';

export const repriseProjectResolver: ResolveFn<QueryParamMapModel> = (
  route,
  state
) => {
  const router = inject(Router);
  const params = route.queryParamMap;
  const uuid = params.get('uuid');
  const producerCode = params.get('producer_code');
  const mode = params.get('mode');
  const entity = params.get('entity');

  if(!uuid || !producerCode || !mode || !entity) {
    router.navigate([Routing.UNAUTHORIZED], {state: {data: 'error.email'}});
    return of();
  }

  return of({
    uuid,
    producerCode,
    mode,
    entity
  });
};
