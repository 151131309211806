import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { catchError, of } from 'rxjs';
import { Routing } from '../../app.routes';
import { GlobalStore } from '../../shared/services/global.store';
import { AcsApiService } from '../services/acs-api.service';
export interface QueryParamMapModel {
  token?: string | null;
  uuid: string | null;
  producerCode: string | null;
  mode: string | null;
  entity: string | null;
}
export interface PayloadABCToken {
  sub: string;
  broker_id: string;
  broker_name: string;
  visitor_id: string;
}
export const projectResolver: ResolveFn<QueryParamMapModel> = (
  route,
  state
) => {
  const globalStore: GlobalStore = inject(GlobalStore);
  const apiService: AcsApiService = inject(AcsApiService);
  const router: Router = inject(Router);
  const params = route.queryParamMap;
  const token = params.get('token');
  const uuid = params.get('uuid');
  const producerCode = params.get('producer_code');
  const mode = params.get('mode');
  const entity = params.get('entity');
  if (token) {
    apiService.setToken(token);
  }
  if (token && !uuid) {
    try {
      const payload: PayloadABCToken = jwtDecode(token);
      if (payload.broker_id) {
        const ratio =
          window.screen.height +
          window.screen.width +
          window.devicePixelRatio +
          window.navigator.hardwareConcurrency;
        const fingerprint =
          ratio.toString() +
          window.navigator.languages +
          window.navigator.userAgent;
        apiService
          .verifyToken(token, fingerprint)
          .pipe(
            catchError((err) => {
              router.navigate([Routing.UNAUTHORIZED]);
              throw err;
            })
          )
          .subscribe({
            next: () => {
              globalStore.setVisitorParams(
                payload.broker_id,
                token,
                fingerprint
              );
              router.navigate([Routing.NEW_PROJECT]);
            }
          });
        return of();
      }
    } catch (err) {
      router.navigate([Routing.UNAUTHORIZED], {
        state: { data: 'token.invalid' }
      });
    }

    router.navigate([Routing.UNAUTHORIZED], {
      state: { data: 'token.invalid' }
    });
    return of();
  }

  globalStore.setUrlParamInfo({
    token,
    uuid,
    producerCode,
    mode,
    entity
  });
  if (uuid) {
    if (!token) {
      router.navigate([Routing.REPRISEPROJET], {
        queryParams: {
          uuid,
          producer_code: producerCode,
          mode,
          entity
        }
      });
    }
  } else {
    router.navigate([Routing.UNAUTHORIZED]);
  }
  return of({
    token,
    uuid,
    producerCode,
    mode,
    entity
  });
};
