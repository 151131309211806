import { inject, Injectable } from '@angular/core';
import { CustomList } from '../model/acs-project.model';
import { ApiService } from '../../core/api/services';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class customListStore {
  readonly #apiService: ApiService = inject(ApiService);
  #cacheSession = new BehaviorSubject<CustomList | null>(null);

  //Renvoie true s'il y a déjà des informations en cache
  #hasCacheSession(): boolean {
    return this.#cacheSession.getValue() !== null;
  }

  //récupérer et stocker en cache les retours de l'API Custom-List
  public getSessionStore(locale: string): Observable<CustomList | null> {
    if (!this.#hasCacheSession()) {
      return this.#apiService
        .getGetExpatTarificationLists({
          _locale: locale
        })
        .pipe(
          map((res) => {
            const customList: CustomList = {
              ...res.data
            };
            this.#cacheSession.next(customList);
            return customList;
          })
        );
    } else {
      return this.#cacheSession.asObservable();
    }
  }
}
