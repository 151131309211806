<div class="is-flex is-flex-direction-column full-height">
  <form [formGroup]="form" class="is-flex is-flex-direction-column mt-2 p-2">
    <h2 class="is-size-4">{{ 'recovery.simulation' | translate }}</h2>
    <p class="has-text-justified mt-2 p-2">
      {{ 'text.recovery.simulation' | translate }}
    </p>
    <mat-form-field appearance="outline" class="form-field mt-2">
      <mat-label for="code" class="mt-2">Code</mat-label>
      <input
        matInput
        id="code"
        placeholder="Code"
        name="code"
        formControlName="code" />
    </mat-form-field>
    <button mat-flat-button (click)="confirmCode()" [disabled]="form.invalid">
      {{ 'key.confirm' | translate }}
    </button>
  </form>
</div>
