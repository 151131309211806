/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BusinessSectorList } from '../../models/business-sector-list';
import { CountryList } from '../../models/country-list';

export interface GetGetExpatTarificationLists$Params {
  '_locale': string;
}

export function getGetExpatTarificationLists(http: HttpClient, rootUrl: string, params: GetGetExpatTarificationLists$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'status'?: number;
'data'?: {
'minimum_subscription_period'?: number;
'nationality_countries'?: CountryList;
'expatriation_countries'?: CountryList;
'business_sectors'?: BusinessSectorList;
'expat_project_deductible'?: Array<number>;
};
}>> {
  const rb = new RequestBuilder(rootUrl, getGetExpatTarificationLists.PATH, 'get');
  if (params) {
    rb.path('_locale', params['_locale'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'status'?: number;
      'data'?: {
      'minimum_subscription_period'?: number;
      'nationality_countries'?: CountryList;
      'expatriation_countries'?: CountryList;
      'business_sectors'?: BusinessSectorList;
      'expat_project_deductible'?: Array<number>;
      };
      }>;
    })
  );
}

getGetExpatTarificationLists.PATH = '/{_locale}/api/1.0/modules/acs/expat/custom-lists';
