/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BeneficiaryNoPremium } from '../../models/beneficiary-no-premium';
import { ExpatClient } from '../../models/expat-client';
import { PricingInformations } from '../../models/pricing-informations';
import { TokenUuidResponse } from '../../models/token-uuid-response';

export interface PutExpatTarificationUpdateProject$Params {

/**
 * Token for project
 */
  token?: string;
  '_locale': string;
  uuid: string;
      body: {
'pricing'?: PricingInformations;
'client'?: ExpatClient;
'producer_code'?: string;
'mode'?: 'expat_internal' | 'expat_external_client' | 'expat_external_producer';
'product'?: string;
'visitor_token'?: string;
'fingerprint'?: string;
'subscription'?: boolean;
'beneficiaries'?: Array<BeneficiaryNoPremium>;
}
}

export function putExpatTarificationUpdateProject(http: HttpClient, rootUrl: string, params: PutExpatTarificationUpdateProject$Params, context?: HttpContext): Observable<StrictHttpResponse<TokenUuidResponse>> {
  const rb = new RequestBuilder(rootUrl, putExpatTarificationUpdateProject.PATH, 'put');
  if (params) {
    rb.header('token', params.token, {});
    rb.path('_locale', params['_locale'], {});
    rb.path('uuid', params.uuid, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TokenUuidResponse>;
    })
  );
}

putExpatTarificationUpdateProject.PATH = '/{_locale}/api/1.0/modules/acs/expat/project/{uuid}';
