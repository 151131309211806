import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { Routing } from '../../app.routes';
import { TranslatePipe } from '../../shared/pipes/translate.pipe';
import { StepperService } from '../../shared/services/stepper.service';

@Component({
  selector: 'acs-unauthorized',
  standalone: true,
  imports: [TranslatePipe, MatIconModule],
  templateUrl: './unauthorized.component.html',
  styles: `
    .height-50vh {
      height: calc(100vh - 132px - 240px);
    }

    .bg-blue {
      background-color: #e9f2ff;
      padding: 1rem;

      p {
        margin-left: 36px;
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnauthorizedComponent implements OnInit {
  readonly #stepperService: StepperService = inject(StepperService);
  readonly #router: Router = inject(Router);
  protected message: string = '';
  public serverMessageError: string = '';
  public ngOnInit(): void {
    this.#stepperService.$isVisible.set(false);
    const url = this.#router.url.replace('/', '');
    if (url === Routing.UNAUTHORIZED) {
      this.message = 'action.interdite';
    }
    if (history.state.data) {
      this.message = 'error.500';
      this.serverMessageError = history.state.data;
    }
  }
}
