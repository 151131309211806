/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ExpatProjectGetResponse } from '../../models/expat-project-get-response';

export interface GetGetExpatTarificationProject$Params {

/**
 * Token for resource
 */
  token?: string;
  '_locale': string;
  uuid: string;
}

export function getGetExpatTarificationProject(http: HttpClient, rootUrl: string, params: GetGetExpatTarificationProject$Params, context?: HttpContext): Observable<StrictHttpResponse<ExpatProjectGetResponse>> {
  const rb = new RequestBuilder(rootUrl, getGetExpatTarificationProject.PATH, 'get');
  if (params) {
    rb.header('token', params.token, {});
    rb.path('_locale', params['_locale'], {});
    rb.path('uuid', params.uuid, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ExpatProjectGetResponse>;
    })
  );
}

getGetExpatTarificationProject.PATH = '/{_locale}/api/1.0/modules/acs/expat/project/{uuid}';
